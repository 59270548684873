import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AOS from "aos"
import "aos/dist/aos.css"
import NewsStrip from "../components/news-strip"
import MetroFares from "../components/metro-fares"

class LC3ServicePage extends Component {
  componentDidMount() {
    AOS.init()
    window.addEventListener("load", AOS.refresh)
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000)
  }

  componentDidUpdate() {
    AOS.refresh()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="LC3"
          description="View route information and buy your ticket for the LC3 service operated by Tetley's Coaches."
        />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent">
            <div className="text-center">
              <h1 className="page-content-heading">LC3 service</h1>
              <p>Beeston Terminus - Leeds City Academy</p>
              <p>Leeds City Academy - Beeston Terminus</p>
            </div>
          </div>

          <div className="body gutter page-content-parent">
            <div className="page-content">
              <div className="page-content__text">
                <h2>Route</h2>
                <p style={{ margin: '15px 0'}}>This service will pick up/set down at all regular bus stops along the following route:</p>
                <h3 style={{ margin: '10px 0'}}>AM route</h3>
                <iframe
                  title="LC3 AM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=1-1Shc_rOwO_B0M4KX5YQfEUreS5je66b"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>Towards</strong> Leeds City Academy
                </p>
                <p>
                  From Beeston terminus via Town Street, Beeston Road, Cemetery Road, Top Moor Side, Domestic Street, Domestic Road, Whitehall Road, Dixon Lane, Upper Wortley Road, Whingate, Wortley Road, Town Street, Branch Road, Armley Rd, Canal Road, Viaduct Road, Willow Road, Burley Road, Woodsley Road, St Johns Terrace, Moorland Road, Hyde Park Road, Woodhouse Street, Rampart Road, Drop off at Cinder Moor Car Park.
                </p>

                <h3 style={{ margin: '10px 0'}}>PM route</h3>
                <iframe
                  title="LC3 PM route map"
                  className="route-map"
                  src="https://www.google.com/maps/d/embed?mid=12QFfLUjgqObFfBavhARnRQ3jFSjbMVmq"
                  width="100%"
                  height="480"
                ></iframe>
                <p>
                  <strong>From</strong> Leeds City Academy
                </p>
                <p>
                  Pick up at Cinder Moor Car Park, Rampart Road, Woodhouse Lane, Clarendon Road, Moorland Road, St Johns Terrace, Woodsley Road, Burley Road, Willow Road, Viaduct Road, Canal Road, Ledgards Way, Crab Lane, Town Street, Wortley Road, Whingate, Upper Wortley Road, Dixon Lane, Whitehall Road, Domestic Road, Domestic Street, Top Moor Side, Cemetery Road, Beeston Road, and Town Street.
                </p>
              </div>
              <div className="page-content__text">
                <h2>Timetable</h2>
                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Morning</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Beeston terminus</td>
                      <td>DEP</td>
                      <td>0730</td>
                    </tr>
                    <tr>
                      <td>Whitehall Rd/Dragon Inn</td>
                      <td>DEP</td>
                      <td>0743</td>
                    </tr>
                    <tr>
                      <td>Armley Town Street</td>
                      <td>DEP</td>
                      <td>0750</td>
                    </tr>
                    <tr>
                      <td>Leeds City Academy</td>
                      <td>ARR</td>
                      <td>0810</td>
                    </tr>
                  </tbody>
                </table>

                <table className="timetable">
                  <thead>
                    <tr>
                      <th colSpan="3">Afternoon</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Leeds City Academy</td>
                      <td>DEP</td>
                      <td>1505</td>
                    </tr>
                    <tr>
                      <td>Armley Town Street</td>
                      <td>DEP</td>
                      <td>1517</td>
                    </tr>
                    <tr>
                      <td>Whitehall Rd/Dragon Inn</td>
                      <td>DEP</td>
                      <td>1527</td>
                    </tr>
                    <tr>
                      <td>Beeston Terminus</td>
                      <td>ARR</td>
                      <td>1545</td>
                    </tr>
                  </tbody>
                </table>

                <h2 style={{ margin: '0 0 20px 0'}}>22/23 fares</h2>
                <p>There is only one payment option for this service:</p>
                <ul>
                  <li><strong>Single one way fare -  £1.</strong></li>
                  <li>Drivers accept cash and contactless payments.</li>
                  <li><strong>PLEASE NOTE:</strong> Metro pre paid passes will not be accepted on this service from September 2022.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <NewsStrip />
      </Layout>
    )
  }
}

export default LC3ServicePage
